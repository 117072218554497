import React from 'react'
import Router, { useRouter } from 'next/router'
import { Link as NextLink } from 'next/link'
import ReactJWPlayer from 'react-jw-player'
import { useQuery } from '@apollo/react-hooks'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import Chip from '@material-ui/core/Chip'
import Grid from '@material-ui/core/Grid'

import { getRecommendedTemplates, getTemplate } from '../../graphql/templates'
import { getLoggedInUser } from '../../graphql/account'
import TemplateSlider from '../TemplateSlider'
import LoadingAnimation from '../LoadingAnimation'
import { PreviewImage } from './TemplatePreview.styles'

const TemplatePreview = () => {
  const router = useRouter()
  const templateId = router.query.template || router.query.dialogTemplateId
  const {
    data: { me }
  } = useQuery(getLoggedInUser, {
    ssr: false,
    fetchPolicy: 'cache-only'
  })

  const { loading, data: Template } = useQuery(getTemplate, {
    variables: { authenticated: !!me, id: templateId },
    skip: !templateId
  })
  const template = Template?.template || {}
  const { data } = useQuery(getRecommendedTemplates, {
    variables: {
      authenticated: !!me,
      first: 10,
      id: templateId,
      orderBy: 'createdAt_DESC'
    },
    skip: !templateId
  })

  const {
    previewUrl,
    name,
    __typename,
    type,
    categories,
    tags,
    hasAccess,
    orientation,
    description,
    subscriptions
  } = !loading && template && template

  const categoryName =
    categories && categories.map((category) => category.name).join(', ')

  // Subscriptions
  const userExtraSubscriptions = me?.extras?.map(
    (extraPlans) => extraPlans.subscriptionParent.name
  )
  const templateExtraPlans = subscriptions
    ?.filter(
      (subscription) => subscription.type.typeName === 'ExtraSubscription'
    )
    .map((subscription) => subscription.name)

  // Plans
  const userHasAIImagePlan = userExtraSubscriptions.includes(
    'A.I. Summer Collection'
  )
  const userHasAIVideoPlan = userExtraSubscriptions.includes(
    'A.I. Summer Animated Collection'
  )
  const templateHasAIImagePlan = templateExtraPlans?.includes(
    'A.I. Summer Collection'
  )
  const templateHasAIVideoPlan = templateExtraPlans?.includes(
    'A.I. Summer Animated Collection'
  )
  const UpgradeButton = ({ url }) => (
    <Button
      variant='contained'
      disableElevation
      size='large'
      color='primary'
      onClick={() => window.open(url, '_blank')}
    >
      UPGRADE NOW
    </Button>
  )

  const handleCustomizeTemplateClick = () =>
    me && hasAccess
      ? Router.push('/editor', `/editor/create/${templateId}`, {
          query: { id: templateId }
        })
      : Router.push('/pricing')

  if (loading)
    return (
      <Box
        display='flex'
        height='100%'
        justifyContent='center'
        alignItems='center'
      >
        <LoadingAnimation />
      </Box>
    )
  if (templateId) {
    return (
      <Box display='flex' p={2} flexDirection='column'>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <Box display='flex' alignItems='stretch' flexDirection='column'>
              {__typename === 'ImageTemplate' ? (
                <PreviewImage src={previewUrl} />
              ) : (
                <ReactJWPlayer
                  playerId='Z9NAbUSZ'
                  playerScript='https://cdn.jwplayer.com/libraries/Z9NAbUSZ.js'
                  file={previewUrl}
                  isAutoPlay
                />
              )}
            </Box>
          </Grid>
          <Grid item xs>
            <Box display='flex' flexDirection='column'>
              <Typography variant='h4'>{name}</Typography>
              <Typography variant='h5' gutterBottom>
                {type.displayName}
              </Typography>
              <Box display='flex' flexWrap='wrap' py={1} alignItems='center'>
                <Typography variant='subtitle2'>Orientation&nbsp;</Typography>
                <Typography variant='h6'>{orientation.name}</Typography>
              </Box>
              <Typography variant='body1'>
                {description}
                {/* Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris
              pulvinar enim eu erat finibus efficitur. In volutpat augue
              consectetur urna porta porta. Maecenas mauris nulla, dignissim sed
              sagittis sit amet, blandit et elit. Aenean quis justo vulputate mi
              rhoncus ullamcorper a quis eros. Cras nulla orci, cursus at ipsum
              eget, mollis tristique ante. Mauris fermentum velit ut lacus
              consequat viverra. Nullam interdum diam urna, in ultrices tellus
              egestas quis. Vestibulum porttitor nisi ipsum, quis cursus justo
              imperdiet sed. Fusce sed elit faucibus augue ullamcorper dictum.
              Nullam a lacus eleifend lectus iaculis pharetra. Quisque sed neque
              quis ipsum vulputate pharetra. Donec eget augue eu felis suscipit
              eleifend. Pellentesque habitant morbi tristique senectus et netus
              et malesuada fames ac turpis egestas. */}
              </Typography>
              <Box display='flex' py={2} alignItems='center'>
                <Typography variant='h5'>{categoryName}</Typography>
              </Box>
              <Box display='flex' flexWrap='wrap' py={1} alignItems='center'>
                <Typography variant='subtitle2'>Keywords&nbsp;</Typography>
                {tags.map((tag) => (
                  <Chip
                    label={tag.name}
                    clickable
                    size='small'
                    color='primary'
                    variant='outlined'
                    style={{ marginLeft: '2px', marginRight: '2px' }}
                    href={`/templates/explore?searchQuery=${tag.name}`}
                    component={NextLink}
                  />
                ))}
              </Box>

              <Box py={2}>
                {me && hasAccess && (
                  <Button
                    variant='contained'
                    disableElevation
                    size='large'
                    color='primary'
                    onClick={handleCustomizeTemplateClick}
                  >
                    Customize This Template
                  </Button>
                )}
              </Box>
              <Box py={2}>
                {!hasAccess &&
                  !userHasAIImagePlan &&
                  templateHasAIImagePlan && (
                    <UpgradeButton url='https://dropmock.com/aisummer' />
                  )}
                {!hasAccess &&
                  !userHasAIVideoPlan &&
                  templateHasAIVideoPlan && (
                    <UpgradeButton url='https://dropmock.com/aianimated' />
                  )}
              </Box>
            </Box>
          </Grid>
        </Grid>
        {data && data.recommendedTemplates && (
          <Box overflow='hidden' px={2}>
            <TemplateSlider
              content={data.recommendedTemplates.edges}
              title={`More of ${categoryName}`}
            />
          </Box>
        )}
      </Box>
    )
  }
  return null
}

export default TemplatePreview
