import React, { useRef } from 'react'
import Slider from 'react-slick'
import { Link as NextLink } from 'next/link'
import ChevronLeft from '@material-ui/icons/ChevronLeft'
import ChevronRight from '@material-ui/icons/ChevronRight'
import Box from '@material-ui/core/Box'
import IconButton from '@material-ui/core/IconButton'
import Typography from '@material-ui/core/Typography'
import Container from '@material-ui/core/Container'

import Template from '../Template'
import { pageSize } from '../../lib/constants/template'

import LinkNoUnderline from '../../lib/styles/components/LinkNoUnderline'

const TemplateSlider = ({
  title,
  content,
  titleClickable,
  link,
  hideTemplateInfo
}) => {
  const sliderRef = useRef(null)

  const { sliderPage } = pageSize

  const settings = {
    lazyLoad: true,
    infinite: false,
    slidesToShow: sliderPage,
    slidesToScroll: sliderPage,
    arrows: false
  }

  const next = () => {
    sliderRef.current.slickNext()
  }
  const previous = () => {
    sliderRef.current.slickPrev()
  }

  // * IMPROVEMENT NEEDED:
  // TODO: THE SLIDER ONLY SHOWS THE FIRST 20 TEMPLATES. NEEDS TO BE ALL OF THEM WITH PAGINATION.

  return (
    <Container maxWidth='lg'>
      <Box my={4}>
        <Box mb={2}>
          {titleClickable ? (
            <LinkNoUnderline component={NextLink} href={link} color='inherit'>
              <Typography variant='h3' href={link}>
                {title}
              </Typography>
            </LinkNoUnderline>
          ) : (
            <Typography variant='h3' href={link}>
              {title}
            </Typography>
          )}
        </Box>
        <Box
          my={1}
          display='flex'
          flexDirection='row'
          justifyContent='center'
          alignItems='center'
          px={1}
        >
          <IconButton color='primary' onClick={previous}>
            <ChevronLeft fontSize='large' />
          </IconButton>
          <Box minWidth='100%'>
            <Slider {...settings} ref={sliderRef}>
              {content.map((element, i) => (
                <div className='seperator' index={i} key={element.node.id}>
                  <Template data={element.node} hideInfo={hideTemplateInfo} />
                </div>
              ))}
            </Slider>
          </Box>
          <IconButton color='primary' onClick={next}>
            <ChevronRight fontSize='large' />
          </IconButton>
        </Box>
      </Box>
    </Container>
  )
}

export default TemplateSlider
